import { getPosts } from '@/server/actions/posts.actions';
import { PostParamsType } from '@/types';
import { useInfiniteQuery } from '@tanstack/react-query';

const useGetAllPosts = ({
  PageSize = 15,
  PageNumber,
  popular,
  ...rest
}: PostParamsType & { popular?: boolean }) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [popular ? 'GetPopularPosts' : 'GetAllPosts', { PageSize, ...rest }],
    queryFn: ({ pageParam }) => getPosts({ PageSize, PageNumber: pageParam, popular, ...rest }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage && pages ? pages?.length + 1 : undefined,
  });

  const payload = data?.pages?.reduce((acc, page) => [...acc, ...page.data], []);

  return { ...other, data: payload, total: data?.pages[0]?.totalCount ?? '0' };
};

export default useGetAllPosts;
