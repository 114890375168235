'use client';
import useGetCommunityInterests from '@/hooks/react-query/communities/queries/useGetCommunityInterests';
import { InterestType } from '@/types';
import React from 'react';

const InterestFilter = () => {
  const { data } = useGetCommunityInterests({ Search: '' });
  const interests = data?.data?.splice(0, 6) ?? [];

  return (
    <div className="flex items-center gap-1">
      <div className="text-gray-brand6 border text-sm font-medium border-purple-brand2 bg-pink-brand-muted flex items-center justify-center py-[6px] px-3 rounded-sm">
        Popular
      </div>
      {interests.map((interest: InterestType) => (
        <div
          key={interest.id}
          className="text-gray-brand capitalize border text-sm font-medium border-gray-brand3 bg-white flex items-center justify-center py-[6px] px-3 rounded-sm text-ellipsis overflow-hidden"
        >
          {interest.name}
        </div>
      ))}
    </div>
  );
};

export default InterestFilter;
