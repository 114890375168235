import { getCommunityInterests } from '@/server/actions/communities.actions';
import { useQuery } from '@tanstack/react-query';

const useGetCommunityInterests = ({
  Search,
  enabled = true,
}: {
  Search: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['GetCommunityInterests', { Search }],
    queryFn: () => getCommunityInterests({ Search }),
    enabled,
  });
};

export default useGetCommunityInterests;
